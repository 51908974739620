<script
    lang="ts"
    setup
>
    type Props = {
        checked?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        checked: false,
    })
</script>

<template>
    <div
        :class="[
            `
                relative
                flex
                flex-col
                items-center
                p-4
                border
                rounded-lg
            `,
            $slots['default-error'] ? 'border-action/error' : 'border-secondary/main',
        ]"
    >
        <input
            key="input"
            class="hidden"
            type="radio"
            name="paymentMethod"
            :checked="props.checked"
        />

        <label
            key="label"
            class="flex flex-row desktop:items-center w-full px-4 pl-10 font-medium leading-[120%] cursor-pointer"
        >
            <span
                key="icon"
                :class="[
                    `
                        absolute
                        flex
                        items-center
                        justify-center
                        w-[20px]
                        h-[20px]
                        left-5
                        rounded-full
                        border-2
                        border-action/default
                        after:content-['']
                        after:w-[10px]
                        after:h-[10px]
                        after:rounded-full
                    `,
                    { 'border-black after:bg-black': checked },
                ]"
            ></span>

            <span
                class="
                    flex
                    flex-col
                    desktop:flex-row
                    desktop:items-center
                    desktop:justify-between
                    gap-4
                    desktop:w-full
                "
            >
                <slot name="label" />
            </span>
        </label>

        <div
            v-if="$slots.default"
            key="default"
            :class="[ 'w-full px-4', { hidden: !checked } ]"
        >
            <AppDivider class="my-4 bg-secondary/main" />

            <slot />

            <AppTransitionCollapse>
                <div
                    v-if="$slots['default-error']"
                    key="default-error"
                    class="flex items-center text-action/error text-xs font-medium leading-[130%] mt-4"
                >
                    <slot name="default-error" />
                </div>
            </AppTransitionCollapse>
        </div>
    </div>
</template>
