<script
    lang="ts"
    setup
>
    import type { Card } from '~/ts/types/card'
    import type { FormSelectOption } from '~/ts/types/form'
    import { useBillingStore } from '~/stores/billing'

    type Props = {
        hideDirect?: boolean
        showEmptyCardListError?: boolean
    }

    const props = defineProps<Props>()

    const methodItemCardRef = ref<ReturnType<typeof defineComponent>>()

    const billingStore = useBillingStore()

    const pending = ref<boolean>(false)

    const selectedCard = computed<Card | undefined>(() => {
        return billingStore.selectedCardId && billingStore.cards.find(v => v.id === billingStore.selectedCardId)
    })

    const cardOptions = computed<FormSelectOption[]>(() => {
        return billingStore.cards.map(item => ({
            value: item.id,
            text: item.card_mask,
            _extra: { type: item.card_type },
        }))
    })

    const submitWayForPayForm = (formString: string): void => {
        const container = document.createElement('div')
        container.innerHTML = formString

        const form = container.querySelector('form')

        document.body.appendChild(form)

        form.submit()
    }

    const addCard = async (): Promise<void> => {
        if (pending.value) {
            return
        }

        pending.value = true

        try {
            const data = await useApi().card.add()

            submitWayForPayForm(data.html)
        } finally {
            pending.value = false
        }
    }

    const deleteCard = async (id: Card['id']): Promise<void> => {
        if (pending.value) {
            return
        }

        const { t } = useLang()

        if (!await useConfirm({
            titleText: t('remove-card') + '?',
            bodyText: t('billing-part-90'),
            confirmText: t('remove'),
        })) {
            return
        }

        pending.value = true

        try {
            await useApi().card.delete({ id })

            billingStore.cards = billingStore.cards.filter(v => v.id !== id)

            if (!billingStore.cards.find(v => v.id === billingStore.selectedCardId)) {
                billingStore.selectedCardId = undefined
            }

            useNotify().push({
                type: 'success',
                tag: 'billing-card',
                text: useLang().t('successfully-deleted'),
            })
        } finally {
            pending.value = false
        }
    }
</script>

<template>
    <BillingPurchaseStepMethodItem
        ref="methodItemCardRef"
        key="card"
        :checked="billingStore.purchaseMethod === 'card'"
        @click="billingStore.purchaseMethod = 'card'"
    >
        <template
            v-if="showEmptyCardListError"
            #default-error
        >
            <AppIconCreditCard
                size="20"
                color="#dd0e0e"
            />

            <div class="text-xs font-medium ml-2">
                {{ $t('billing-part-80') }}
            </div>
        </template>

        <template #label>
            {{ $t('billing-part-34') }}

            <span class="flex items-center gap-2 h-[50px]">
                <AppIconMasterCard size="51" />

                <AppIconVisa size="53" />
            </span>
        </template>

        <template #default>
            <template v-if="selectedCard">
                <div>
                    <AppFormFieldSelect
                        v-model="billingStore.selectedCardId"
                        name="status"
                        :label="$t('billing-part-36')"
                        :options="cardOptions"
                        :dropdown-args="{ offsetY: 4, width: '100%', disableClosing: pending }"
                        container-class="!w-full"
                        class="!mb-2"
                    >
                        <template #activator-input="{ inputValue, inputClass, activatorProps }">
                            <span :class="[ inputClass, 'flex items-center h-[28px]' ]">
                                <AppIconMasterCard
                                    v-if="selectedCard.card_type === 'mastercard'"
                                    key="mastercard"
                                />

                                <AppIconVisa
                                    v-else
                                    key="visa"
                                />

                                <input
                                    key="input"
                                    :value="inputValue"
                                    :class="inputClass"
                                    readonly
                                    class="pointer-events-none ml-2"
                                    autocomplete="off"
                                    @focusin="activatorProps.toggle"
                                />
                            </span>
                        </template>

                        <template #option-text="{ option }">
                            <div class="w-full flex items-center">
                                <span class="flex flex-grow items-center">
                                    <AppIconMasterCard
                                        v-if="option._extra.type === 'mastercard'"
                                        key="mastercard"
                                    />

                                    <AppIconVisa
                                        v-else
                                        key="visa"
                                    />

                                    <span class="ml-1">
                                        {{ option.text }}
                                    </span>
                                </span>

                                <AppButtonIcon
                                    extra-small
                                    :disabled="pending"
                                    @click.stop="deleteCard(option.value)"
                                >
                                    <AppIconTrash
                                        size="20"
                                        class="ml-auto"
                                    />
                                </AppButtonIcon>
                            </div>
                        </template>
                    </AppFormFieldSelect>
                </div>
            </template>

            <AppButton
                secondary
                medium
                :class="[ 'w-full', { 'mt-4': !!selectedCard } ]"
                :loading="pending"
                @click="addCard()"
            >
                {{ $t('billing-part-37') }}
            </AppButton>
        </template>
    </BillingPurchaseStepMethodItem>

    <!--    <BillingPurchaseStepMethodItem -->
    <!--        :checked="billingStore.purchaseMethod === 'paypal'" -->
    <!--        @click="billingStore.purchaseMethod = 'paypal'" -->
    <!--    > -->
    <!--        <template #label> -->
    <!--            PayPal -->

    <!--            <img -->
    <!--                src="~/assets/img/pro/PayPal.svg" -->
    <!--                alt="PayPal" -->
    <!--                class="ml-4" -->
    <!--            /> -->
    <!--        </template> -->

    <!--        <template #default> -->
    <!--            <template v-if="tempPaypalToggle"> -->
    <!--                <AppButton -->
    <!--                    medium -->
    <!--                    class="w-full" -->
    <!--                    @click="tempPaypalToggle = !tempPaypalToggle" -->
    <!--                > -->
    <!--                    {{ $t('billing-part-38') }} -->
    <!--                </AppButton> -->
    <!--            </template> -->

    <!--            <template v-else> -->
    <!--                <div class="uppercase text-text/secondary text-[10px] font-medium"> -->
    <!--                    {{ $t('billing-part-39') }} -->
    <!--                </div> -->

    <!--                <div class="flex flex-row mt-4"> -->
    <!--                    <div class="font-medium truncate max-w-[200px]"> -->
    <!--                        work.belka@gmail.com -->
    <!--                    </div> -->

    <!--                    <a -->
    <!--                        class="ml-auto font-medium underline underline-offset-4 text-xs" -->
    <!--                        href="#" -->
    <!--                        @click="tempPaypalToggle = !tempPaypalToggle" -->
    <!--                    > -->
    <!--                        {{ $t('remove') }} -->
    <!--                    </a> -->
    <!--                </div> -->
    <!--            </template> -->
    <!--        </template> -->
    <!--    </BillingPurchaseStepMethodItem> -->

    <BillingPurchaseStepMethodItem
        v-if="!props.hideDirect"
        key="direct"
        :checked="billingStore.purchaseMethod === 'direct'"
        @click="billingStore.purchaseMethod = 'direct'"
    >
        <template #label>
            <span>
                Apple Pay {{ $t('and') }} Google Pay
            </span>

            <span class="flex items-center gap-4 h-[50px]">
                <img
                    src="~/assets/img/pro/PayApple.svg"
                    alt="PayPal"
                />

                <img
                    src="~/assets/img/pro/PayGoogle.svg"
                    alt="PayPal"
                />
            </span>
        </template>
    </BillingPurchaseStepMethodItem>
</template>
