<script
    lang="ts"
    setup
>
    const expand = ref<boolean>(false)

    const { t } = useLang()

    const features = computed(() => [
        {
            title: t('communication-channels'),
            iconComponent: resolveComponent('AppIconAccessPoint'),
            items: [
                { title: t('billing-part-49'), available: true },
                { title: t('billing-part-50'), available: true },
                { title: t('billing-part-51'), available: true },
            ],
        },
        {
            title: t('productivity'),
            iconComponent: resolveComponent('AppIconLightningBoltOutline'),
            items: [
                { title: t('billing-part-52'), available: true },
                { title: t('billing-part-53'), available: true },
                { title: t('billing-part-54'), available: true },
                { title: t('billing-part-55'), available: true },
                { title: t('billing-part-56'), available: true },
                { title: t('billing-part-57'), available: true },
                { title: t('billing-part-58'), available: false },
                { title: t('billing-part-59'), available: false },
            ],
        },
        {
            title: t('widget'),
            iconComponent: resolveComponent('AppIconWidgetOutline'),
            items: [
                { title: t('billing-part-60'), available: true },
                { title: t('billing-part-61'), available: true },
                { title: t('billing-part-62'), available: true },
                { title: t('billing-part-63'), available: false },
            ],
        },
        {
            title: t('chats'),
            iconComponent: resolveComponent('AppIconMessageTextOutline'),
            items: [
                { title: t('billing-part-63-new-1'), available: true },
                { title: t('billing-part-63-new-2'), available: true },
                { title: t('billing-part-64'), available: true },
                { title: t('billing-part-65'), available: true },
                { title: t('billing-part-66'), available: true },
                { title: t('billing-part-67'), available: true },
                { title: t('billing-part-68'), available: true },
                { title: t('billing-part-69'), available: true },
                { title: t('billing-part-70'), available: false },
                { title: t('billing-part-71'), available: false },
                { title: t('billing-part-72'), available: false },
            ],
        },
        {
            title: t('knowledge-base'),
            iconComponent: resolveComponent('AppIconBooksThin'),
            items: [
                { title: t('billing-part-73'), available: true },
                { title: t('billing-part-74'), available: true },
                { title: t('billing-part-75'), available: true },
                { title: t('analytics'), available: false },
                { title: t('billing-part-76'), available: false },
            ],
        },
    ])
</script>

<template>
    <div
        ref="titleRef"
        class="
            pb-16
            pt-32
            tablet:pt-50
            text-[28px]
            tablet:text-[40px]
            font-medium
            text-center
            uppercase
            mx-auto
            tracking-tighter
            leading-[120%]
        "
    >
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t('billing-part-25')"></span>
        <!-- eslint-enable vue/no-v-html -->
    </div>

    <!-- features header -->
    <div class="mx-auto mb-4 grid grid-cols-6 items-center max-w-[724px]">
        <div class="col-span-6 grid grid-cols-6 gap-x-6 font-bold">
            <!-- eslint-disable @stylistic/max-len -->
            <div class="rounded-t-[1.25rem] border border-background/stone col-span-3 col-start-0 md:col-span-2 md:col-start-3 flex flex-col p-6 tablet:p-11">
                <div class="leading-[1.2] text-[20px] tablet:text-[1.625rem]">
                    <div class="hidden sm:block">
                        {{ $t('billing-part-77') }}
                    </div>

                    <div class="block sm:hidden">
                        Free
                    </div>
                </div>

                <div class="mb-11 text-text/secondary tablet:text-lg leading-[1.2]">
                    {{ $t('billing-part-78') }}
                </div>

                <div class="text-3xl tablet:text-6xl leading-[1.2]">
                    $0
                </div>

                <div class="text-text/secondary tablet:text-lg leading-[1.2]">
                    /{{ $t('month').toLowerCase() }}
                </div>
            </div>
            <!-- eslint-enable @stylistic/max-len -->

            <div class="rounded-t-[1.25rem] col-span-3 md:col-span-2 flex flex-col p-6 tablet:p-11 bg-primary/active">
                <div class="leading-[1.2] text-[20px] tablet:text-[1.625rem]">
                    PRO
                </div>

                <div class="mb-11 text-text/secondary tablet:text-lg leading-[1.2]">
                    {{ $t('billing-part-78') }}
                </div>

                <div class="text-3xl tablet:text-6xl leading-[1.2]">
                    $25
                </div>

                <div class="text-text/secondary tablet:text-lg leading-[1.2]">
                    /{{ $t('month').toLowerCase() }}
                </div>
            </div>
        </div>
    </div>

    <!-- features compare -->
    <div class="mx-auto max-w-[724px] relative grid gap-y-14 mt-14">
        <template
            v-for="(group, index) in features"
            :key="group.title"
        >
            <div
                v-if="index <= 1 || expand"
                class="grid grid-cols-6 items-center gap-y-5"
            >
                <div class="col-span-6 mb-6 grid grid-cols-6 items-center pb-4 border-b border-[#000000]">
                    <div
                        class="
                            col-span-4
                            flex
                            items-center
                            text-[20px]
                            tablet:text-[28px]
                            leading-[1.2]
                            font-medium
                            sm:col-span-3
                            md:col-span-2
                       "
                    >
                        <component
                            :is="group.iconComponent"
                            size="34"
                            color="#000"
                            class="mr-2 inline-block sm:mr-4"
                        />

                        <span class="max-sm:overflow-hidden max-sm:text-ellipsis whitespace-nowrap">
                            {{ group.title }}
                        </span>
                    </div>

                    <div class="col-span-1 col-start-5 text-center text-text/secondary sm:col-start-0 sm:col-span-2">
                        <div class="hidden sm:block">
                            {{ $t('billing-part-77') }}
                        </div>

                        <div class="block sm:hidden">
                            Free
                        </div>
                    </div>

                    <div class="col-span-1 text-center text-text/secondary md:col-span-2">
                        PRO
                    </div>
                </div>

                <template
                    v-for="feature in group.items"
                    :key="feature.title"
                >
                    <div class="col-span-4 font-medium leading-[1.2] sm:col-span-3 md:col-span-2">
                        {{ feature.title }}
                    </div>

                    <div class="col-span-1 flex justify-center sm:col-span-2">
                        <AppIconCheckBold
                            v-if="feature.available"
                            key="available"
                            size="17"
                            color="#00A807"
                        />

                        <AppIconCancel
                            v-else
                            key="not-available"
                            size="17"
                            color="#DD0E0E"
                        />
                    </div>

                    <div class="col-span-1 flex justify-center md:col-span-2">
                        <AppIconCheckBold
                            size="17"
                            color="#00A807"
                        />
                    </div>

                    <div class="col-span-6 h-[1px] bg-[#e6e6e6]"></div>
                </template>
            </div>
        </template>

        <div
            v-if="!expand"
            key="grad"
            :class="$style['grad']"
        ></div>
    </div>

    <AppButton
        secondary
        medium
        class="mx-auto mt-16"
        @click="expand = !expand"
    >
        <AppIconChevronDown
            color="#000"
            :class="{ 'rotate-180': expand }"
        />

        {{ $t('billing-part-79') }}
    </AppButton>
</template>

<style
    lang="sass"
    module
    scoped
>
    .grad
        position: absolute
        width: 100%
        height: 350px
        bottom: 0
        background: rgb(255, 255, 255)
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1)
</style>
