<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable @stylistic/max-len -->
        <path
            d="M15.2451 6.60425H8.75537V18.3972H15.2451V6.60425Z"
            fill="#FF5F00"
        />

        <path
            d="M9.16746 12.5C9.16643 11.3642 9.42095 10.2432 9.91174 9.22157C10.4025 8.19999 11.1167 7.30471 12.0003 6.6035C10.9061 5.73384 9.5921 5.19302 8.20836 5.04283C6.82463 4.89265 5.42704 5.13918 4.17531 5.75423C2.92359 6.36928 1.86823 7.32805 1.12989 8.52094C0.391549 9.71383 0 11.0927 0 12.5C0 13.9073 0.391549 15.2862 1.12989 16.4791C1.86823 17.672 2.92359 18.6307 4.17531 19.2458C5.42704 19.8608 6.82463 20.1073 8.20836 19.9572C9.5921 19.807 10.9061 19.2662 12.0003 18.3965C11.1167 17.6953 10.4025 16.8 9.91174 15.7784C9.42096 14.7568 9.16644 13.6357 9.16746 12.5V12.5Z"
            fill="#EB001B"
        />

        <path
            d="M24 12.5C24.0001 13.9073 23.6086 15.2861 22.8703 16.479C22.132 17.6719 21.0766 18.6307 19.8249 19.2458C18.5732 19.8608 17.1757 20.1073 15.792 19.9572C14.4082 19.807 13.0942 19.2662 12.0001 18.3965C12.8829 17.6946 13.5965 16.7992 14.0872 15.7777C14.5779 14.7563 14.8329 13.6356 14.8329 12.5C14.8329 11.3644 14.5779 10.2437 14.0872 9.22226C13.5965 8.20084 12.8829 7.30541 12.0001 6.6035C13.0942 5.73384 14.4082 5.19301 15.792 5.04283C17.1757 4.89265 18.5732 5.13918 19.8249 5.75424C21.0766 6.3693 22.132 7.32807 22.8703 8.52096C23.6086 9.71386 24.0001 11.0927 24 12.5V12.5Z"
            fill="#F79E1B"
        />

        <path
            d="M23.2927 17.1472V16.9057H23.389V16.8566H23.1438V16.9057H23.2401V17.1472H23.2927ZM23.7687 17.1472V16.8561H23.6936L23.6071 17.0563L23.5207 16.8561H23.4455V17.1472H23.4985V16.9276L23.5796 17.1169H23.6346L23.7157 16.9271V17.1472H23.7687Z"
            fill="#F79E1B"
        />
        <!-- eslint-enable @stylistic/max-len -->
    </AppIcon>
</template>
