<script
    lang="ts"
    setup
>
    const scrollAnimations = []

    const vScrollAnimation = {
        mounted: (el, binding) => {
            scrollAnimations.push({
                el,
                style: binding.value.style || '',
                class: binding.value.class || '',
                scrollPosition: el.getBoundingClientRect().top - (window.innerHeight * 0.85), // 85% offset
            })
        },
    }

    defineExpose({ scrollAnimations })
</script>

<template>
    <div class="grid grid-cols-1 gap-10 lg:grid-cols-2">
        <!-- case info -->
        <BillingCard
            class="px-6 pt-8 tablet:pt-15 tablet:px-13"
            :animation-class="false"
        >
            <BillingCardTitle class="pb-6">
                {{ $t('billing-part-3') }}

                <BillingCardTitleHighlight>
                    {{ $t('billing-part-4') }}
                </BillingCardTitleHighlight>

                {{ $t('billing-part-5') }}
            </BillingCardTitle>

            <img
                class="mx-auto"
                src="~/assets/img/pro/case-info.png"
                srcset="~/assets/img/pro/case-info@2x.png 2x"
                alt="Case 1"
            />
        </BillingCard>

        <!-- case typing -->
        <BillingCard
            class="pt-8 pl-6 tablet:pt-15 tablet:pr-0 tablet:pb-6 tablet:px-13"
            :animation-class="false"
        >
            <BillingCardTitle class="pb-6">
                {{ $t('billing-part-6') }}

                <BillingCardTitleHighlight>
                    {{ $t('billing-part-7') }}
                </BillingCardTitleHighlight>
            </BillingCardTitle>

            <img
                class="my-auto ml-auto w-full"
                src="~/assets/img/pro/case-typing.png"
                srcset="~/assets/img/pro/case-typing@2x.png 2x"
                alt="Case 2"
            />
        </BillingCard>

        <!-- case templates -->
        <BillingCard
            v-scroll-animation="{ class: $style['card-animation'] }"
            class="pt-8 tablet:py-15 tablet:pr-0 tablet:px-13 lg:col-span-2"
        >
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
                <div class="flex flex-col items-center justify-center">
                    <BillingCardTitle class="pb-6">
                        {{ $t('billing-part-8') }}

                        <BillingCardTitleHighlight>
                            {{ $t('billing-part-9') }}
                        </BillingCardTitleHighlight>

                        {{ $t('billing-part-10') }}<br>{{ $t('billing-part-11') }}
                    </BillingCardTitle>

                    <img
                        src="~/assets/img/pro/case-template-tag.png"
                        srcset="~/assets/img/pro/case-template-tag@2x.png 2x"
                        alt="Case 3"
                    />
                </div>

                <div class="hidden lg:block">
                    <img
                        class="my-auto ml-auto"
                        src="~/assets/img/pro/case-template-popover.png"
                        srcset="~/assets/img/pro/case-template-popover@2x.png 2x"
                        alt="Case 3"
                    />
                </div>
            </div>
        </BillingCard>

        <!-- case widget -->
        <BillingCard
            v-scroll-animation="{ class: $style['card-animation'] }"
            class="tablet:pt-15 tablet:px-13 lg:col-span-2"
        >
            <div class="grid grid-cols-1 tablet:gap-8 lg:grid-cols-2">
                <div class="tablet:hidden pt-8 px-6">
                    <BillingCardTitle class="mb-2 tablet:mb-10 w-fill lg:mb-52">
                        <BillingCardTitleHighlight>
                            {{ $t('billing-part-12') }}
                        </BillingCardTitleHighlight>

                        {{ $t('billing-part-13') }}
                    </BillingCardTitle>
                </div>

                <img
                    class="justify-self-center"
                    src="~/assets/img/pro/case-widget.png"
                    srcset="~/assets/img/pro/case-widget@2x.png 2x"
                    alt="Case widget"
                />

                <div class="flex flex-col max-tablet:pt-10">
                    <div
                        class="
                            tablet:mb-15
                            flex
                            h-full
                            flex-col
                            items-center
                            justify-center
                            px-6
                            tablet:px-10
                            lg:mb-0
                            lg:px-4
                        "
                    >
                        <BillingCardTitle class="max-tablet:hidden mb-10 w-fill lg:mb-52">
                            <BillingCardTitleHighlight>
                                {{ $t('billing-part-12') }}
                            </BillingCardTitleHighlight>

                            {{ $t('billing-part-13') }}
                        </BillingCardTitle>

                        <BillingCardTitle class="w-full">
                            {{ $t('billing-part-14') }}

                            <BillingCardTitleHighlight>
                                {{ $t('billing-part-15') }}
                            </BillingCardTitleHighlight>
                        </BillingCardTitle>
                    </div>

                    <img
                        class="mt-auto w-full"
                        src="~/assets/img/pro/case-widget-desktop.png"
                        srcset="~/assets/img/pro/case-widget-desktop@2x.png 2x"
                        alt="Case desktop"
                    />
                </div>
            </div>
        </BillingCard>

        <!-- case images -->
        <BillingCard
            v-scroll-animation="{ class: $style['card-animation'] }"
            animation-class="-translate-x-2"
            class="pt-8 px-6 tablet:pt-15 tablet:px-13"
        >
            <BillingCardTitle>
                {{ $t('billing-part-16') }}

                <BillingCardTitleHighlight>
                    {{ $t('billing-part-17') }}
                </BillingCardTitleHighlight>

                {{ $t('billing-part-18') }}
            </BillingCardTitle>

            <div class="my-6 flex flex-row justify-center gap-5">
                <span
                    v-for="extension in [ 'JPG', 'PNG', 'GIF' ]"
                    :key="extension"
                    class="w-[72px] bg-background/success rounded-lg px-4 py-2 font-medium text-xl"
                >
                    {{ extension }}
                </span>
            </div>

            <img
                class="w-full"
                src="~/assets/img/pro/case-image.png"
                srcset="~/assets/img/pro/case-image@2x.png 2x"
                alt="Case Image"
            />
        </BillingCard>

        <!-- case kb -->
        <BillingCard
            v-scroll-animation="{ class: $style['card-animation'] }"
            animation-class="translate-x-2"
            class="row-span-2 tablet:px-13"
        >
            <div class="flex flex-col items-center justify-center">
                <img
                    src="~/assets/img/pro/case-kb-stat.png"
                    srcset="~/assets/img/pro/case-kb-stat@2x.png 2x"
                    alt="Case desktop"
                />

                <BillingCardTitle class="tablet:my-8 tablet:px-10 text-center">
                    {{ $t('billing-part-19') }}

                    <BillingCardTitleHighlight>
                        {{ $t('billing-part-20') }}
                    </BillingCardTitleHighlight>

                    {{ $t('billing-part-21') }}
                </BillingCardTitle>

                <div class="tablet:py-16 text-6xl">
                    🤘
                </div>

                <img
                    src="~/assets/img/pro/case-kb.png"
                    srcset="~/assets/img/pro/case-kb@2x.png 2x"
                    alt="Case desktop"
                />
            </div>
        </BillingCard>

        <!-- case gifs -->
        <BillingCard
            v-scroll-animation="{ class: $style['card-animation'] }"
            animation-class="-translate-x-2"
            class="overflow-hidden pt-8 px-6 tablet:pt-15 tablet:px-13"
        >
            <BillingCardTitle class="mb-8">
                {{ $t('billing-part-22') }}

                <BillingCardTitleHighlight>
                    {{ $t('billing-part-23') }}
                </BillingCardTitleHighlight>

                {{ $t('billing-part-24') }}
            </BillingCardTitle>

            <BillingCardGifs />
        </BillingCard>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .card-animation
        opacity: 1
        transform: translate3d(0, 0, 0)
</style>
