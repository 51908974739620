<script
    lang="ts"
    setup
>
    import { useBillingStore } from '~/stores/billing'
    import { useUserStore } from '~/stores/user'

    const billingStore = useBillingStore()

    const showModal = ref<boolean>(false)

    const { t, tc } = useLang()
    const { tablet } = useWindowSize()

    const items = computed(() => {
        if (!billingStore.activePlan) {
            return [
                {
                    title: t('tariff'),
                    subtitle: 'Freemium',
                },
                {
                    title: t('billing-part-41'),
                    subtitle: APP_FREEMIUM_OPERATOR_QUANTITY,
                },
            ]
        }

        const userStore = useUserStore()

        const startDate = dateUtil.fromSQL(userStore.activeSubscription.start_at)
        const endDate = dateUtil.fromSQL(userStore.activeSubscription.end_at)
        const daysLeft = endDate.diff(startDate, 'days').toObject().days

        return [
            {
                title: t('tariff'),
                subtitle: billingStore.activePlan.name,
            },
            {
                title: t('billing-part-41'),
                subtitle: userStore.activeSubscription.operator_quantity,
            },
            {
                title: t('billing-part-42'),
                subtitle: tc('day-pluralized', daysLeft),
            },
            {
                title: t('billing-part-43'),
                subtitle: endDate.toFormat(DATE_DISPLAY_DATE_FORMAT),
            },
        ]
    })
</script>

<template>
    <div
        key="title"
        class="mt-10 tablet:mt-10 desktop:mt-25 mb-8 tablet:mb-14 text-[20px] tablet:text-[40px] font-medium"
    >
        {{ $t('billing-part-26') }}
    </div>

    <div
        key="content"
        class="grid grid-cols-1 tablet:grid-cols-[repeat(4,minmax(180px,max-content)),1fr] gap-6"
    >
        <div
            v-for="{ title, subtitle } in items"
            :key="title"
            class="flex tablet:flex-col justify-between gap-4 tablet:gap-6"
        >
            <div class="text-[14px] text-text/secondary tablet:font-medium">
                {{ title }}:
            </div>

            <div class="leading-[120%] font-medium">
                {{ subtitle }}
            </div>
        </div>

        <div
            v-if="billingStore.activePlan"
            key="action"
            class="flex items-end"
        >
            <AppButton
                secondary
                :small="tablet"
                class="max-tablet:w-full"
                @click="showModal = true"
            >
                {{ $t('settings') }}
            </AppButton>
        </div>
    </div>

    <AppTransitionSlideXMobileOnly>
        <LazyBillingActivePlanModal
            v-if="showModal"
            key="modal"
            @close="showModal = false"
        />
    </AppTransitionSlideXMobileOnly>
</template>
