<template>
    <span :class="$style['highlight']">
        <slot />
    </span>
</template>

<style
    lang="sass"
    module
    scoped
>
    .highlight
        @apply text-text/green
</style>
